
.my_eclipse {
    position: fixed;
    background-color: rgba(246, 248, 246, 0.904);
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    z-index: 1000000;
    transform: translate(-50%,-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    /* .progress {
        background-color: #00000000;
        width: 60px;
        height: 60px;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);

    }
    .progress i {
        font-size: 100px;
        color: rgb(109, 233, 185);
    } */
}